import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';

export const ProductsContext = React.createContext<{prices?: any; products?: any;}>({});

export const ProductsProvider = ({ children }) => {
  const productsData = useStaticQuery(productsStaticQuery)
  const [ productsState, setProductsState ] = useState({});

  useEffect(() => {
    // normalized by the priceId
    // select objects by the price id
    // for both products and prices
    const products = productsData
    .allContentfulProductPage
    .edges
    .reduce((obj, item) => (obj[item.node.priceId] = item.node, obj) ,{});

  const prices = productsData
    .allStripePrice
    .edges
    .reduce((obj, item) => (obj[item.node.id] = item.node, obj) ,{});

    setProductsState({
      products,
      prices,
    });
  }, []);

  return (
    <ProductsContext.Provider value={productsState}>{ children }</ProductsContext.Provider>
  );
}

const productsStaticQuery = graphql`
  query {
    allContentfulProductPage {
      edges {
        node {
          productName
          priceId
          images {
            gatsbyImageData(height: 50, width: 50, layout: FIXED)
          }
        }
      }
    }
    allStripePrice {
      edges {
        node {
          id
          unit_amount
        }
      }
    }
  }
`