export const cartStateReducer = (
  state: CartState,
  action: CartActionPayload
) => {
  const newState = { ...state };
  switch (action.type) {
    case CartActions.ADD_TO_CART:
      newState.items[action.payload.productId] = action.payload.numItems;
      newState.itemIds = Object.keys(newState.items);
      newState.totalItems = Object.values(newState.items).reduce(
        (currentValue, previousValue) =>
          currentValue + previousValue
      );
      return newState;
    case CartActions.CLEAR_ALL_ITEMS_FROM_CART:
      return {};
    case CartActions.CLEAR_ITEM_FROM_CART:
      delete newState.items[action.payload.productId];
      const totalItems = Object.values(newState.items);
      if (totalItems.length) {
        newState.totalItems = Object.values(newState.items).reduce(
          (currentValue, previousValue) =>
            currentValue + previousValue
        );
      } else {
        newState.totalItems = 0;
      }

      newState.itemIds = Object.keys(newState.items);
      return newState;
    default:
      return state;
  }
};

export enum CartActions {
  ADD_TO_CART = "ADD_TO_CART",
  CLEAR_ITEM_FROM_CART = "CLEAR_ITEM_FROM_CART",
  CLEAR_ALL_ITEMS_FROM_CART = "CLEAR_ALL_ITEMS_FROM_CART",
  DEFAULT = "DEFAULT",
}

export interface CartState {
  items: CartItem[];
  itemIds: string[];
  totalItems: number;
}

export interface CartItem {
  productId: string;
  numItems: number;
}

interface CartActionPayload {
  type: CartActions;
  payload: CartItem;
}
