import { cartStateReducer } from "./state";
import React, { useReducer } from "react";

export const CartContext = React.createContext<any>({});

export const CartProvider = ({ children }) => {
  const [cartState, dispatchCartActions] = useReducer<any>(cartStateReducer, {
    items: {},
    itemIds: {},
    totalItems: 0,
  });

  const store = React.useMemo(() => ({cartState, dispatchCartActions}), [cartState]);
  
  return (
    <CartContext.Provider value={store}>{children}</CartContext.Provider>
  );
};
