const React = require("react")
const { CartProvider } = require("./src/components/providers/Cart");
const { ProductsProvider } = require("./src/components/providers/Products");

exports.wrapRootElement = ({ element }) => {
  return (
    <ProductsProvider>
      <CartProvider>
        {element}
      </CartProvider>
    </ProductsProvider>
  )
}